
import {mapGetters} from 'vuex';
import {rzContainer, rzIconCross} from 'razlet-ui';

export default {
  name: 'info-message',
  components: {
    rzContainer,
    rzIconCross,
  },
  data() {
    return {
      isHide: true,
    };
  },
  computed: {
    ...mapGetters(['alertMessage']),
  },
  mounted() {
    // if (!localStorage.getItem('hideAlertMessage')) localStorage.setItem('hideAlertMessage', 'false');
    // this.isHide = localStorage.getItem('hideAlertMessage') === this.alertMessage.id;
  },
  methods: {
    hideMessage() {
      localStorage.setItem('hideAlertMessage', this.alertMessage.id);
      this.isHide = true;
    },
  },
};
