import { render, staticRenderFns } from "./main-footer.vue?vue&type=template&id=36f47a28"
import script from "./main-footer.vue?vue&type=script&lang=js"
export * from "./main-footer.vue?vue&type=script&lang=js"
import style0 from "./main-footer.vue?vue&type=style&index=0&id=36f47a28&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainLogo: require('/opt/iaravia.ru/src/components/main-logo.vue').default,RzContainer: require('/opt/iaravia.ru/node_modules/razlet-ui/src/components/container/index.vue').default})
